<template>
    <div class="flex-container" style="flex-direction: column;">
        <div class="flex-container">
            <div class="input_title">
              {{ label_text }}
              <div class="information-popup-container">
                <span v-if="has_popup_info" @mouseover="show_info = true" @mouseleave="show_info = false" :style="'z-index: ' + z_index + ';' ">&#128712;</span>
                <div v-if="show_info" class="info-popup" :style="'z-index: ' + z_index + ';' ">
                  {{ popup_info }}
                </div>
              </div>
            </div>
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
  name: 'InputSection',
  props: {
    label_text: {
      type: String,
      required: true,
    },
    has_popup_info: {
      type: Boolean,
      default: false,
    },
    popup_info: String,
    z_index: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      show_info: false,
    }
  },
};
</script>