// =============== CONSTANTS ===============
// The names of the constants here try to follow the following rules:
// <USED FOR>_<HTML ELEMENT/STRING(SUFFIX/PREFIX)/OTHER>_...
export const SERVER_IP = window.origin; //"http://127.0.0.1:5000";//

export const CSS_CLASS_POPUP = "popup";
export const CSS_CLASS_POPUP_CONTENT = "popup-content";
export const CSS_CLASS_FLEX_CONTAINER = "flex-container";
export const CSS_CLASS_IGNORE_BUTTON_STYLE = "ignore_button_style";
export const CSS_CLASS_REMOVABLE_ELEMENT = "removable_element";
export const CSS_CLASS_INVISIBLE = "invisible";
export const CSS_CLASS_REMOVABLE_BLOCK_TABLE = "removable_block_table";


export const FREQUENCY_BANDS = ["S-band", "X-band", "Ka-band"];

export const ID_BUTTON_SATELLITE = "add_satellite";
export const ID_SECTION_SATELLITES = "satellites";
export const ID_BUTTON_ADD_PREFERRED_SITES = "add_preferred_site";
export const ID_SECTION_PREFERRED_SITE = "preferred_sites";
export const ID_SECTION_AVOID_SITES = "sites_to_avoid";
export const ID_BUTTON_ADD_AVOID_SITE = "add_avoid_sites";
export const ID_INPUT_SATELLITE_SEARCH = "satellite_input";
export const ID_INPUT_SATELLITE_TLE = "tle_input";
export const ID_INPUT_SATELLITE_OEM = "oem_input";
export const ID_INPUT_ADD_SITE = "add_site_input";
export const ID_SECTION_FREQUENCY_BAND = "frequency_bands";
export const ID_SECTION_DATA_RATE = "data-rates";
export const ID_INPUT_PASSES_PREDICTOR = "passes_predictor_checkbox";
export const ID_INPUT_TOTAL_DURATION_PASSES_PER_DAY = "min_pass_duration_in_minutes_minutes";
export const ID_INPUT_MINIMUM_DURATION_MINUTES = "minimum_pass_duration_minutes";
export const ID_DIV_ERROR_BOX = "error_box";
export const ID_SPAN_ERROR_TEXT = "error_text";
export const ID_DIV_WANTED_PASSES = "wanted_passes"
export const ID_TABLE_WANTED_PASSES = "wanted_passes_table"
export const ID_DIV_PREDICTED_PASSES = "predicted_passes"
export const ID_TABLE_PREDICTED_PASSES = "predicted_passes_table"

export const NAME_DIV_CHOSEN_SATELLITE = "chosen_satellites";
export const NAME_DIV_PRIORITIZED_SITES = "prio_sites";
export const NAME_DIV_AVOID_SITES = "avoid_sites";

export const LIST_SEARCH_SATELLITES = "search_satellites";
export const LIST_SEARCH_GROUNDSTATIONS = "search_groundstations";

export const TEXT_TITLE_ADD_SITELLITE_POPUP = "Use one of the following:";
export const TEXT_BUTTON_ADD_SATELLITE = "Add satellite";
export const TEXT_RADIO_HIGH_OPTION = "High";
export const TEXT_RADIO_MEDIUM_OPTION = "Medium";
export const TEXT_RADIO_LOW_OPTION = "Low";
export const TEXT_BUTTON_ADD_SITE = "Add site";
export const TEXT_BUTTON_FREQUENCY_BAND_SHOW = "Show frequency bands";
export const TEXT_BUTTON_FREQUENCY_BAND_HIDE = "Hide frequency bands";
export const TEXT_BUTTON_DATA_RATE_SHOW = "Show data-rate options";
export const TEXT_BUTTON_DATA_RATE_HIDE = "Hide data-rate options";
export const TEXT_BUTTON_PREFERRED_SITE_SHOW = "Show preference section";
export const TEXT_BUTTON_PREFERRED_SITE_HIDE = "Hide preference section";
export const TEXT_TITLE_ADD_SATELLITE = "Add satellite";
export const TEXT_TITLE_ADD_SITE = "Add site";
export const TEXT_BUTTON_CLOSE_ICON = "✕";

export const SUFFIX_ID_SELECTED_BAND = "-band";
export const SUFFIX_ID_DATA_RATE = "-band-data-rate";
export const SUFFIX_ID_DATA_RATE_NUMBER = "-band-data-rate-number";
export const SUFFIX_RADIO_NAME_PRIORITY = "-priority";
export const SUFFIX_FREQUENCY_INPUT = "-frequency-specifier";
