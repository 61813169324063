import { createApp } from 'vue'
import App from './App.vue'
import "./assets/main.css";
import "./assets/input.css";

import msalInstance from "@/auth/msal"

(async () => {
    await msalInstance.initialize();
    await msalInstance.login();
    await msalInstance.getAPIToken();
    
    while (! await msalInstance.isLoggedIn());

    createApp(App).mount('#app');
})();