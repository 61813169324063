<template>
    <div :class="constants.CSS_CLASS_FLEX_CONTAINER" style="padding-bottom: 1vh;" >
        <span>{{ optionText }}</span>
        <input :type="inputType" :list="listID" :id="inputID" :value="setValue" @change="onInputChange">
        <slot></slot>
    </div>
</template>

<script>
import * as constants from '@/assets/constants';


export default {
  name: 'PopupOption',
  props: {
    optionText: {
        type: String,
        required: true,
    },
    inputType: {
        type: String,
        required: true,
    },
    listID: String,
    inputID: String,
    setValue: {
      type: [String, Number],
    },
  },
  data() {
    return {
        constants,
    }
  },
  methods: {
    onInputChange(event) {
      if (this.inputType == "file") {
        let files = event.target.files;
        if (files.length == 0) return;
        if (files.length > 1) console.error("Multiple files not supported. Please just select one!");
        let reader = new FileReader();
        reader.onload = (text) => {
          let tles = text.target.result;
          this.$emit('update:newValue', tles);
        };
        reader.readAsText(files[0]);
      }
      if (this.setValue == undefined) return;
      if (this.inputType == "checkbox") {
        this.$emit('update:setValue', event.target.checked);
      } else {
        this.$emit('update:setValue', event.target.value);
      }
    }
  }
};
</script>