<template>
    <div class="flex-container sub-flex-container" style="flex-direction: column;">
        <slot></slot>
    </div>
</template>

<script>
export default {
  name: 'InputSubSection',
};
</script>