<template>
    <div class="flex-container error-box" style="flex-direction: column;">
        <div :class="constants.CSS_CLASS_FLEX_CONTAINER">
            <h3 style="margin: 0;">Error:</h3>
            <button :class="constants.CSS_CLASS_IGNORE_BUTTON_STYLE" style="min-width: 25px; margin-left: auto; text-align: right; margin-right: 10px;" @click="close_box">
                {{ constants.TEXT_BUTTON_CLOSE_ICON }}
            </button>
        </div>
        <div>
            {{ error_text }}
        </div>
    </div>
</template>

<script>
import * as constants from "../assets/constants";

export default {
  name: 'ErrorBox',
  props: {
    error_text: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
        constants,
    }
  },
  methods: {
    close_box() {
        this.$emit("update:show_error_box", false);
    },
  },
};
</script>