<template>
    <PopupBase :header-text="header_text" :visible="visible" @update:clicked="hidePopup">
        <div :class="constants.CSS_CLASS_FLEX_CONTAINER" style="flex-direction: column;">
            <div v-if="allow_custom_sites == true" :class="constants.CSS_CLASS_FLEX_CONTAINER">
                <select v-model="selected_option">
                    <option v-for="option in add_groundstation_options" :key="option" :value="option">{{ option }}</option>
                </select>
            </div>
            <PopupOption v-if="selected_option == add_groundstation_options[0] || !allow_custom_sites" :option-text="text_add_groundstation" inputType="search" v-model:setValue="selected_groundstation_name" listID="api_groundstations">
                <datalist id="api_groundstations">
                    <option v-for="(gs_info, gs_name) in groundstations" :key="gs_name" :value="gs_name">
                        {{ gs_name }}
                    </option>
                </datalist>
            </PopupOption>

            <div v-if="selected_option == add_groundstation_options[1] && allow_custom_sites == true" :class="constants.CSS_CLASS_FLEX_CONTAINER" style="flex-direction: column; align-items:flex-start;">
                <PopupOption option-text="Groundstation name:" v-model:setValue="input_value_groundstation_name" input-type="text"></PopupOption>
                <PopupOption option-text="Latitude:" v-model:setValue="input_value_latitude" inputType="number"></PopupOption>
                <PopupOption option-text="Longitude:" v-model:setValue="input_value_longitude" inputType="number"></PopupOption>
                <PopupOption v-for="[i, mission_purpose] in Object.entries(optional_mission_purposes)" :key="i" :option-text="`Allows for ${i} mission purposes: `" v-model:setValue="mission_purpose.is_selected" inputType="checkbox">
                </PopupOption>
                <PopupOption v-for="[i, frequency_band] in Object.entries(optional_frequency_bands)" :key="i" :option-text="`Supports ${i}`" v-model:setValue="frequency_band.is_supported" inputType="checkbox"></PopupOption>
                <PopupOption v-for="[i, frequency_band] in selected_frequency_bands" :key="i" :option-text="`Number of antennas that support ${i}`" v-model:setValue="frequency_band.number_of_antennas" inputType="number"></PopupOption>
            </div>

            <button @click="addGroundstation">
                Add groundstation
            </button>
        </div>
    </PopupBase>
</template>

<script>
import PopupBase from './PopupBase.vue';
import PopupOption from './PopupOption.vue';
import * as constants from '../assets/constants'
import { ref, reactive, computed } from 'vue';

export default {
    name: 'AddGroundstationPopup',
    components: {
        PopupBase,
        PopupOption,
    },
    props: {
        header_text: {
            type: String,
            required: true,
        },
        visible: {
            type: Boolean,
            required: true,
        },
        groundstations: {
            type: Object,
            required: true,
        },
        frequency_bands: {
            type: Array,
            default: () => [],
        },
        allow_custom_sites: {
            type: Boolean,
            default: false,
        },
        mission_purposes: {
            type: Array,
            default: () => [],
        }
    },
    setup(props) {
        let add_groundstation_options = ref(["From our database", "Create custom site"]);
        let selected_option = ref(add_groundstation_options.value[0]);
        let optional_frequency_bands = reactive([]);
        if (props.frequency_bands !== undefined) {
            for (let i in props.frequency_bands) {
                optional_frequency_bands[props.frequency_bands[i]] = {is_supported: false, number_of_antennas: 0};
            }
        }
        let optional_mission_purposes = reactive([]);
        if (props.mission_purposes !== undefined) {
            for (let i in props.mission_purposes) {
                optional_mission_purposes[props.mission_purposes[i]] = {is_selected: false};
            }
        }

        let selected_frequency_bands = computed(() => {
            return Object.entries(optional_frequency_bands).filter((e) => e[1].is_supported);
        });

        let selected_mission_purposes = computed(() => {
            return Object.entries(optional_mission_purposes).filter((e) => e[1].is_selected).map((e) => e[0]); 
        });

        return {
            add_groundstation_options,
            optional_frequency_bands,
            selected_frequency_bands,
            selected_option,
            optional_mission_purposes,
            selected_mission_purposes,
            input_value_groundstation_name: "New Groundstation",
            input_value_latitude: "69.649208",
            input_value_longitude: "18.955324",
        };
    },
    data() {
        return {
            text_add_groundstation: "Groundstations in our system:",
            selected_groundstation_name: "",
            constants,

        }
    },
    methods: {
        hidePopup(visible) {
            this.$emit('update:clicked', visible);
        },
        addGroundstation() {
            if (this.selected_option === this.add_groundstation_options[0]) {
                if (this.selected_groundstation_name == "") throw new Error("No ground station selected!");
            }

            if (this.selected_option === this.add_groundstation_options[1]) {
                if (this.input_value_groundstation_name == "") throw new Error("The custom groundstation you are trying to make has to have a name!");
                if (this.input_value_latitude == "" || isNaN(parseFloat(this.input_value_latitude)) || parseFloat(this.input_value_latitude) < -90.0 || parseFloat(this.input_value_latitude) > 90.0) throw new Error("The latitude value needs to be a valid decimal number between -90.0 and 90.0!");
                if (this.input_value_longitude == "" || isNaN(parseFloat(this.input_value_longitude)) || parseFloat(this.input_value_longitude) < -180.0 || parseFloat(this.input_value_longitude) > 180.0) throw new Error("The longitude value needs to be a valid decimal number between -180.0 and 180.0!");
                if (this.selected_mission_purposes.length === 0) throw new Error("The groundstation has to support at least one mission purpose type!");
                if (this.selected_frequency_bands.length === 0) throw new Error("The groundstation needs to support at least one frequency type!");
                this.selected_groundstation_name = "";
            }

            this.$emit("update:add_groundstation", this.selected_groundstation_name, this.input_value_groundstation_name, this.input_value_latitude, this.input_value_longitude, this.selected_frequency_bands, this.selected_mission_purposes);
        },
    }
}
</script>