<template>
    <PopupBase :headerText="headerText" :visible="visible" @update:clicked="hidePopup">
      <div :class="constants.CSS_CLASS_FLEX_CONTAINER" style="flex-direction: column;">
        <div :class="constants.CSS_CLASS_FLEX_CONTAINER">
          <select v-model="selected_option">
            <option v-for="option in add_satellite_options" :key="option" :value="option">{{ option }}</option>
          </select>
        </div>
        <PopupOption v-if="selected_option == add_satellite_options[0]" :optionText="text_add_satellites_in_our_system" inputType="search" v-model:setValue="selected_system_satellite_name" listID="api_satellites">
          <datalist id="api_satellites">
            <option v-for="(sat_info, sat_name) in satellites" :key="sat_name" :value="sat_name">
              {{ sat_name }}
            </option>
          </datalist>
        </PopupOption>
        <PopupOption v-if="selected_option == add_satellite_options[1]" @update:newValue="updateSelectedTLEFileContents" :optionText="text_add_tle_file" inputType="file">
        
        </PopupOption>
        <div v-if="selected_option == add_satellite_options[2]" :class="constants.CSS_CLASS_FLEX_CONTAINER" style="flex-direction: column; align-items:flex-start;">
          <span>{{ text_add_constellation }}</span>
          <div>
            <button @click="setStandardSSOValues">Load standard SSO</button>
            <button @click="setStandardInclinedValues">Load standard inclined</button>
          </div>
          <PopupOption option-text="Constellation name:" v-model:setValue="input_value_constellation_name" input-type="text"></PopupOption>
          <PopupOption option-text="Number of satellites:" v-model:setValue="input_value_num_sats" inputType="number"></PopupOption>
          <PopupOption option-text="Altitude (km):" v-model:setValue="input_value_alt" inputType="number"></PopupOption>
          <PopupOption option-text="E:" v-model:setValue="input_value_E" inputType="number"></PopupOption>
          <PopupOption option-text="I:" v-model:setValue="input_value_I" inputType="number"></PopupOption>
          <PopupOption option-text="RAAN:" v-model:setValue="input_value_RAAN" inputType="number"></PopupOption>
          <PopupOption option-text="W:" v-model:setValue="input_value_W" inputType="number"></PopupOption>
          <PopupOption option-text="Starting TA:" v-model:setValue="input_value_TA" inputType="number"></PopupOption>
        </div>
        <button @click="addSatellites">
          Add satellite
        </button>
      </div>
    </PopupBase>
</template>

<script>
import PopupBase from './PopupBase.vue';
import PopupOption from './PopupOption.vue';

import * as constants from '@/assets/constants';
import { ref } from 'vue';
import { TLESatellite, CustomSatellite } from '@/assets/classes';
import { CustomOrbit } from '@/assets/classes';

let earth_radius = 6371;

let standard_SSO_values = {
  num_sats: 2,
  alt: 500,
  e: 0,
  i: 97.5,
  raan: 0,
  w: 0,
  ta: 0,
};

let standard_inclined_values = {
  num_sats: 2,
  alt: 500,
  e: 0,
  i: 60,
  raan: 0,
  w: 0,
  ta: 0,
};

export default {
  name: 'AddSatellitePopup',
  components: {
    PopupBase,
    PopupOption,
  },
  props: {
    visible: {
        type: Boolean,
        required: true,
    },
    satellites: {
      type: Object,
      required: true,
    },
  },
  setup() {
    let add_satellite_options = ref(["From our database", "From TLE file", "Create constellation"]);
    let selected_option = ref(add_satellite_options.value[0]);

    return {
      add_satellite_options,
      selected_option,
    };
  },
  data() {
    return {
        headerText: "Add satellite",
        text_add_satellites_in_our_system: "Satellites in our system:",
        text_add_tle_file: "Add satellites from TLE file:",
        text_add_constellation: "Add constellation/custom orbit",
        constants,
        selected_system_satellite_name: "",
        selected_tle_file_contents: "",
        input_value_constellation_name: "Constellation 1",
        input_value_num_sats: standard_SSO_values.num_sats,
        input_value_alt: standard_SSO_values.alt,
        input_value_E: standard_SSO_values.e,
        input_value_I: standard_SSO_values.i,
        input_value_RAAN: standard_SSO_values.raan,
        input_value_W: standard_SSO_values.w,
        input_value_TA: standard_SSO_values.ta,
    }
  },
  methods: {
    hidePopup(visible) {
      this.$emit('update:clicked', visible);
    },
    setStandardSSOValues() {
      this.input_value_num_sats = standard_SSO_values.num_sats;
      this.input_value_alt = standard_SSO_values.alt;
      this.input_value_E = standard_SSO_values.e;
      this.input_value_I = standard_SSO_values.i;
      this.input_value_RAAN = standard_SSO_values.raan;
      this.input_value_W = standard_SSO_values.w;
      this.input_value_TA = standard_SSO_values.ta;
    },
    setStandardInclinedValues() {
      this.input_value_num_sats = standard_inclined_values.num_sats;
      this.input_value_alt = standard_inclined_values.alt;
      this.input_value_E = standard_inclined_values.e;
      this.input_value_I = standard_inclined_values.i;
      this.input_value_RAAN = standard_inclined_values.raan;
      this.input_value_W = standard_inclined_values.w;
      this.input_value_TA = standard_inclined_values.ta;
    },
    addSatellites() {
      let satellites_to_add = [];
      let tles_text = "";
      let lines = "";
      let tles = {};
      switch (this.selected_option) {
        case this.add_satellite_options[0]:
          if (!(this.selected_system_satellite_name in this.satellites)) {
            throw new Error("Could not find satellite with name: " + this.selected_system_satellite_name + ". In our system.");
          }
          satellites_to_add.push(new TLESatellite(this.selected_system_satellite_name, this.satellites[this.selected_system_satellite_name].tle));
          break;
        case this.add_satellite_options[1]:
          if (this.selected_tle_file_contents == "") throw new Error("You have not selected a TLE file or it's empty");
          tles_text = this.selected_tle_file_contents.replaceAll("\r", "");
          if (tles_text == "") throw new Error("The file you specified did not have any contents!");
          lines = tles_text.split("\n");
          tles = {};
          for (let i = 0; i < lines.length; i += 3) {
            let one_part = lines[i] + "\n" + lines[i+1] + "\n" + lines[i+2] + "\n";
            tles[lines[i]] = one_part;
          }
          Object.keys(tles).forEach(key => {
            satellites_to_add.push(new TLESatellite(key, tles[key]));
          })
          break;
        case this.add_satellite_options[2]:
          if (this.input_value_constellation_name === "") throw new Error("The value 'Constellation name' was not set!");
          if (this.input_value_num_sats === "") throw new Error("The value 'Number of satellites' was not set!");
          if (this.input_value_alt === "") throw new Error("The value 'A' was not set!");
          if (this.input_value_E === "") throw new Error("The value 'E' was not set!");
          if (this.input_value_I === "") throw new Error("The value 'I' was not set!");
          if (this.input_value_RAAN === "") throw new Error("The value 'RAAN' was not set!");
          if (this.input_value_W === "") throw new Error("The value 'W' was not set!");
          if (this.input_value_TA === "") throw new Error("The value 'TA' was not set!");
          for (let i = 0; i < this.input_value_num_sats; i += 1) {
            satellites_to_add.push(new CustomSatellite(this.input_value_constellation_name + "_" + (i+1), new CustomOrbit(+this.input_value_alt + +earth_radius, +this.input_value_E, +this.input_value_I, +this.input_value_RAAN, +this.input_value_W, (+this.input_value_TA + +(+i * 360.0 / +this.input_value_num_sats))%360)));
          }
          break;
        default:
          throw new Error("The satellite option you are trying to add is not implemented: " + this.selected_option);
      //     // break;
      }
      this.$emit("update:add_satellites", satellites_to_add);
    },
    updateSelectedTLEFileContents(contents) {
      this.selected_tle_file_contents = contents;
    }
  },
};
</script>