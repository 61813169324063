<template>
    <header>
        <h1>{{ header_text }}</h1>
    </header>
    <slot></slot>
</template>

<script>
export default {
  name: 'MainPage',
  props: {
    header_text: String,
  }
}
</script>