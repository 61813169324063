<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <!-- <OverlayPopup header_text="Overlay test"></OverlayPopup> -->
  <MainPage header_text="Autosite" v-if="currentPage === 'simulator'">
    <section class="no-top-margin">
      <button @click="currentPage='my_files'; getMyFiles();">Go to my files</button>
      <InputSection label_text="Satellite(s):">
        <button @click="show_satellite_section = !show_satellite_section">
          <div v-if="!show_satellite_section">OPEN</div><div v-if="show_satellite_section">CLOSE</div> SATELLITE SECTION
        </button>
      </InputSection>
      <InputSubSection v-if="show_satellite_section">
        <button @click="show_add_satellite_popup = true" style="width: 50vw;">Add satellite</button>
        <AddSatellitePopup headerText="Add satellite" :visible="show_add_satellite_popup" :satellites="api_satellites" @update:clicked="updateAddSatelitePopup" @update:add_satellites="addSatellitesToSelected"></AddSatellitePopup>
        <table :class="constants.CSS_CLASS_REMOVABLE_BLOCK_TABLE">
          <RemovableBlockRow v-for="(satellite, i) in this.selected_satellites" :key="i" :value="satellite" :object_name="satellite.name" @update:clicked="removeSatteliteFromSelection"></RemovableBlockRow>
        </table>
      </InputSubSection>

      <InputSection label_text="Mission purpose:" :has_popup_info="true" :z_index="80" popup_info='If you choose "Dual" or "Military", TROLL and SVALSAT will be excluded from the simulation, because they cannot be used for such purposes.'>
        <select id="mission_purpose" value="" v-model="mission_purpose">
          <option value="" hidden>Choose...</option>
          <option v-for="(purpose, i) in mission_purposes" :key="i" :value="purpose">{{ purpose }}</option>
        </select>
      </InputSection>

      <InputSection label_text="Frequency bands:">
        <button @click="show_frequency_band_selection = !show_frequency_band_selection">
          <div v-if="!show_frequency_band_selection">OPEN</div><div v-if="show_frequency_band_selection">CLOSE</div> FREQUENCY BANDS SECTION
        </button>
      </InputSection>
      <InputSubSection v-if="show_frequency_band_selection">
        <FrequencyBandSelection v-for="(checked, band) in frequency_bands_states" :key="band" :frequency_band="band" @update:checked="updateActivatedBands" :checked="checked"></FrequencyBandSelection>
      </InputSubSection>

      <!-- <InputSection label_text="Wanted data rates:">
        <button @click="show_wanted_datarates_section = !show_wanted_datarates_section">
          <div v-if="!show_wanted_datarates_section">OPEN</div><div v-if="show_wanted_datarates_section">CLOSE</div> WANTED DATARATES SECTION
        </button>
      </InputSection>
      <InputSubSection v-if="show_wanted_datarates_section">
        <WantedDatarateSelection v-for="(checked, band) in frequency_bands_states" :key="band" :frequency_band="band" :visible="checked"></WantedDatarateSelection>
      </InputSubSection> -->

      <InputSection label_text='Do you want to check for contact times on all our groundstations?' :has_popup_info="true" :z_index="40" popup_info='This will check contact times for all the groundstations Autosite has and return information for all the groundstations independent of the wanted contact time below.'>
        <input id="passes_predictor_checkbox" type="checkbox" v-model="want_num_passes_predictor">
      </InputSection>

      <InputSection label_text="How much contact time do you want per day? (minutes)">
        <input id="min_pass_duration_in_minutes_minutes" type="number" name="Write a number" v-model="contact_time_per_day">
      </InputSection>

      <InputSection label_text="Minimum pass duration: (minutes)">
        <input id="minimum_pass_duration_minutes" type="number" name="Write a number" v-model="minimum_pass_duration_minutes">
      </InputSection>

      <!-- <InputSection label_text="Number of simulation days:">
        <input type="range" min="1" max="30" v-model="num_days">
        <span> {{ num_days }}</span>
      </InputSection>-->

      <InputSection label_text="Minimum elevation angle (0-70 degrees):">
        <input id="elevation_angle" type="number" name="Write a number between 0 and 89" v-model="elevation_angle"/>
      </InputSection>

      <InputSection label_text="Do you want to use the AWS groundstations?">
        <input type="checkbox" v-model="use_aws_groundstations">
      </InputSection>

      <InputSection label_text="Select preferred site(s):">
        <button @click="show_preferred_sites_section = !show_preferred_sites_section">
          <div v-if="!show_preferred_sites_section">OPEN</div><div v-if="show_preferred_sites_section">CLOSE</div> PREFERRED SITES SECTION
        </button>
      </InputSection>
      <InputSubSection v-if="show_preferred_sites_section">
        <button @click="show_add_groundstation_popup = true" style="width: 50vw;">Add groundstation</button>
        <AddGroundstationPopup headerText="Add preferred groundstation" :visible="show_add_groundstation_popup" :groundstations="api_groundstations" @update:clicked="updateAddGroundstationPopup" @update:add_groundstation="addPreferredGroundstation" :frequency_bands="frequency_bands" :allow_custom_sites="true" :mission_purposes="mission_purposes"> </AddGroundstationPopup>
        <table :class="constants.CSS_CLASS_REMOVABLE_BLOCK_TABLE">
          <RemovableBlockRow v-for="(gs, i) in this.preferred_groundstations" :key="i" :value="gs" :object_name="gs.name" @update:clicked="removePreferredGroundstation">
            <td><span>-</span></td>
            <td><span>Priority: </span></td>
            <td><input type="radio" id="low" value="low" v-model="gs.rating" :name="gs.name"></td>
            <td><label for="low">low, </label></td>
            <td><input type="radio" id="medium" value="medium" v-model="gs.rating" :name="gs.name"></td>
            <td><label for="medium">medium, </label></td>
            <td><input type="radio" id="high" value="high" v-model="gs.rating" :name="gs.name"></td>
            <td><label for="high">high</label></td>
          </RemovableBlockRow>
        </table>
      </InputSubSection>

      <InputSection label_text="Select site(s) to avoid:">
        <button @click="show_avoid_sites_section = !show_avoid_sites_section">
          <div v-if="!show_avoid_sites_section">OPEN</div><div v-if="show_avoid_sites_section">CLOSE</div> AVOID SITES SECTION
        </button>
      </InputSection>
      <InputSubSection v-if="show_avoid_sites_section">
        <button @click="show_add_groundstation_avoid_popup = true" style="width: 50vw;">Add groundstation</button>
        <AddGroundstationPopup headerText="Add groundstation to avoid" :visible="show_add_groundstation_avoid_popup" :groundstations="api_groundstations" @update:clicked="updateAddGroundstationToAvoidPopup" @update:add_groundstation="addGroundstationToAvoid"> </AddGroundstationPopup>
        <table :class="constants.CSS_CLASS_REMOVABLE_BLOCK_TABLE">
          <RemovableBlockRow v-for="(gs, i) in this.groundstations_to_avoid" :key="i" :value="gs" :object_name="gs.name" @update:clicked="removeGroundstationToAvoid"></RemovableBlockRow>
        </table>
      </InputSubSection>

      <ErrorBox v-if="show_error" @update:show_error_box="setShowErrorBox" :error_text="error_text"></ErrorBox>

      <button style="margin-left: 1%;" @click="lookForSites" :disabled="is_looking_for_site"><b>{{ start_simulation_button_text }}</b></button>
    </section>

    <section v-if="Object.keys(report_data.wanted_passes).length > 0 || Object.keys(report_data.passes_predictor_simple).length > 0">
      <h1>Our recommendations:</h1>
      <div>
        <button v-if="report_data.pdf_name !== undefined && (typeof report_data.pdf_name === 'string' || report_data.pdf_name instanceof String)" @click="downloadPDF">Download PDF</button>
        <button v-if="report_data.csv_name !== undefined && (typeof report_data.csv_name === 'string' || report_data.csv_name instanceof String)" @click="downloadCSV">Download CSV</button>
      </div>
      <div v-if="Object.keys(report_data.wanted_passes).length > 0">
        <h3>Based on wanted contact time: </h3>
        <table name="data_to_show">
          <tr>
            <th>Station</th>
            <!-- <th>Amount of passes</th> -->
            <th>Coverage %</th>
            <th>Average connection duration per day no overlap (min)</th>
            <th>Total connection duration per day no overlap (min)</th>
            <th>Highest contact duration (min)</th>
            <th>Average contact duration (min)</th>
            <th>Average passes per day no overlap</th>
            <th>Average gap time (min)</th>
            <th>Highest gap time (min)</th>
            <th>Average highest gap time of the satellite(s independent of each other) (min)</th>
            <th>Average max gap time 95th percentile (min)</th>
          </tr>
          <tr v-for="(data, name) in report_data.wanted_passes" :key="name" :value="name">
            <td>{{ name }}</td>
            <!-- <td>{{ report_data.wanted_passes_simple[name][0] }}</td> --> <!-- TODO: ROUND NUMBERS TO TWO DECIMAL PLACES! -->
            <td>{{ data.percent_coverage.toFixed(2) }}</td>
            <td>{{ data.avg_cdpdno.toFixed(2) }}</td>
            <td>{{ data.total_cdpdno.toFixed(2) }}</td>
            <td>{{ data.max_contact_duration.toFixed(2) }}</td>
            <td>{{ data.avg_contact_duration.toFixed(2) }}</td>
            <td>{{ data.avg_ppdno.toFixed(2) }}</td>
            <td>{{ data.avg_gap_time.toFixed(2) }}</td>
            <td>{{ data.max_gap_time.toFixed(2) }}</td>
            <td>{{ data.avg_max_gap_time.toFixed(2) }}</td>
            <td>{{ data.avg_max_gap_95th_percentile.toFixed(2) }}</td>
          </tr>
        </table>
      </div>
      <div v-if="Object.keys(report_data.passes_predictor_simple).length > 0">
        <h3>All groundstations independent of each other: </h3>
        <table name="data_to_show">
          <tr>
            <th>Station</th>
            <!-- <th>Amount of passes</th> -->
            <th>Coverage %</th>
            <th>Average connection duration per day no overlap (min)</th>
            <th>Total connection duration per day no overlap (min)</th>
            <th>Highest contact duration (min)</th>
            <th>Average contact duration (min)</th>
            <th>Average passes per day no overlap</th>
            <th>Average gap time (min)</th>
            <th>Highest gap time (min)</th>
            <th>Average highest gap time of the satellite(s independent of each other) (min)</th>
            <th>Average max gap time 95th percentile (min)</th>
          </tr>
          <tr v-for="(data, name) in report_data.passes_predictor_complex" :key="name" :value="name">
            <td>{{ name }}</td>
            <!-- <td>{{ report_data.passes_predictor_simple[name][0] }}</td> -->
            <td>{{ data.percent_coverage.toFixed(2) }}</td>
            <td>{{ data.avg_cdpdno.toFixed(2) }}</td>
            <td>{{ data.total_cdpdno.toFixed(2) }}</td>
            <td>{{ data.max_contact_duration.toFixed(2) }}</td>
            <td>{{ data.avg_contact_duration.toFixed(2) }}</td>
            <td>{{ data.avg_ppdno.toFixed(2) }}</td>
            <td>{{ data.avg_gap_time.toFixed(2) }}</td>
            <td>{{ data.max_gap_time.toFixed(2) }}</td>
            <td>{{ data.avg_max_gap_time.toFixed(2) }}</td>
            <td>{{ data.avg_max_gap_95th_percentile.toFixed(2) }}</td>
          </tr>
        </table>
      </div>
    </section>
  </MainPage>

  <MainPage header_text="Autosite" v-if="currentPage === 'my_files'">
    <div class="no-top-margin">
      <div class="flex-container" style="flex-direction: column; margin-left: 25vw; margin-right: 25vw;">
        <button @click="currentPage='simulator'; file_urls=[];">Go to simulator</button>
        <h2 style="margin-top: 40px">Files (from the last 7 days):</h2>
        <button v-for="file in file_urls" :key="file" @click="openMyFile(file)">{{ file.split("/")[4].split("?")[0] }}</button>
      </div>
    </div>
  </MainPage>
</template>

<script>
import { ref } from 'vue';

// import HelloWorld from './components/HelloWorld.vue'
// import OverlayPopup from './components/OverlayPopup.vue';
import MainPage from './components/MainPage.vue';
import InputSection from './components/InputSection.vue';
import InputSubSection from './components/InputSubSection.vue';
import FrequencyBandSelection from './components/FrequencyBandSelection.vue';
// import WantedDatarateSelection from './components/WantedDatarateSelection.vue';
import AddSatellitePopup from './components/AddSatellitePopup.vue';
import RemovableBlockRow from './components/RemovableBlockRow.vue'
import * as constants from "./assets/constants.js"
import ErrorBox from './components/ErrorBox.vue';

// import axios from 'axios'; // This is for talking with the flask backend
import {getSatellites, getGroundstations, getAPIHeaderOptions} from "./scripts/rest_api";
import {Satellite, Groundstation, DataForRating, ReportData, SimulationParams} from "./assets/classes";
import AddGroundstationPopup from './components/AddGroundstationPopup.vue';

const START_ANALYSIS_TEXT = "Start analysis";

let frequency_bands = ['S', 'X', 'Ka'];
let mission_purposes = ['Civilian', 'Dual', 'Military'];
let activated_frequency_bands = {};
let api_satellites = ref({});
let api_groundstations = ref({});

frequency_bands.forEach(band => {
  activated_frequency_bands[band] = ref(false);
});

(getSatellites()).then((res) => {
  let new_dict = {}
  res.data.forEach(satellite => {
    new_dict[satellite.name] = satellite;
  });
  api_satellites.value = new_dict;
});
(getGroundstations()).then((res) => {
  let new_dict = {}
  res.data.forEach(gs => {
    new_dict[gs.name] = gs;
  });
  api_groundstations.value = new_dict;
});

export default {
  name: 'App',
  components: {
    // HelloWorld
    // OverlayPopup
    MainPage,
    InputSection,
    InputSubSection,
    FrequencyBandSelection,
    // WantedDatarateSelection,
    AddSatellitePopup,
    RemovableBlockRow,
    AddGroundstationPopup,
    ErrorBox,
},

data() {
    return {
      constants,
      mission_purposes,
      show_frequency_band_selection: false,
      show_wanted_datarates_section: false,
      show_satellite_section: false,
      show_preferred_sites_section: false,
      show_avoid_sites_section: false,
      show_add_satellite_popup: false,
      show_add_groundstation_popup: false,
      show_add_groundstation_avoid_popup: false,
      want_num_passes_predictor: false,
      contact_time_per_day: undefined,
      use_aws_groundstations: false,
      minimum_pass_duration_minutes: 1.0,
      mission_purpose: "",
      frequency_bands_states: activated_frequency_bands,
      api_satellites,
      api_groundstations,
      selected_satellites: [],
      preferred_groundstations: [],
      groundstations_to_avoid: [],
      report_data: new ReportData({passes_predictor: undefined, wanted_passes: undefined}),
      is_looking_for_site: false,
      show_error: false,
      error_text: "",
      start_simulation_button_text: START_ANALYSIS_TEXT,
      elevation_angle: 5,
      num_days: 7,
      frequency_bands,
      currentPage: "simulator",
      file_urls: [],
          }
  },
  methods: {
    updateActivatedBands(value, band) {
      activated_frequency_bands[band].value = value;
    },
    updateAddSatelitePopup(visible) {
      this.show_add_satellite_popup = visible;
    },
    addSatellitesToSelected(satellites_to_add) {
      satellites_to_add.forEach(satellite => {
        try {
          if (this.selected_satellites.some((sat) => sat.name == satellite.name)) {
            this.error_text = "A satellite already exists in the selected satellites list that has that name, so we're skipping it. Satellite: " + this.toStringObject(satellite);
            this.setShowErrorBox(true);
            console.error("A satellite already exists in the selected satellites list that has that name, so we're skipping it. Satellite: " + satellite);
          } else {
            this.selected_satellites.push(new Satellite(satellite, undefined));
          }
        } catch(e) {
          console.error(e);
        }
      });
      this.show_add_satellite_popup = false;
    },
    removeSatteliteFromSelection(satellite_name) {
      try {
        if (!this.selected_satellites.some((sat) => sat.name == satellite_name)) {
          this.error_text = "There does not exist any satellite that you have selected that has that name!";
          this.setShowErrorBox(true);
          console.error("There does not exist any satellite that you have selected that has that name!");
        } else {
          this.selected_satellites = this.selected_satellites.filter((sat) => !(sat.name == satellite_name));
        }
      } catch(e) {
        console.error(e)
      }
    },
    // this.input_value_groundstation_name, this.input_value_latitude, this.input_value_longitude, this.selected_frequency_bands
    addPreferredGroundstation(gs_name, custom_gs_name, custom_latitude, custom_longitude, custom_frequencies, custom_allowed_data_purposes) {
      try {
        if (this.preferred_groundstations.some((gs) => gs.name == gs_name)) {
          this.error_text = "This preferred groundstation is already selected!";
          this.setShowErrorBox(true);
          console.error("This preferred groundstation is already selected!");
        } else {
          let gs;
          if (gs_name !== "" && gs_name !== undefined && this.api_groundstations[gs_name] !== undefined) {
            gs = new Groundstation(this.api_groundstations[gs_name]);
          } else {
            let supported_frequency_bands = [];
            let amount_of_antennas = {};
            
            for (let i in custom_frequencies) {
              supported_frequency_bands.push(custom_frequencies[i][0]+"-band");
              amount_of_antennas[custom_frequencies[i][0]+"-band"] = custom_frequencies[i][1].number_of_antennas;
            }
            
            gs = new Groundstation({
              station_id: custom_gs_name,
              name: custom_gs_name,
              latitude: custom_latitude,
              longitude: custom_longitude,
              altitude: 0,
              supported_frequency_bands: supported_frequency_bands,
              allowed_data_purposes: custom_allowed_data_purposes,
              rating: "low",
              amount_of_antennas: amount_of_antennas, 
              score: 100.0,
            });
          }
          gs.rating = "low";
          this.preferred_groundstations.push(gs);
        }
      } catch(e) {
        this.error_text = "Failed to add preferred groundstation because: " + this.toStringObject(e);
        this.setShowErrorBox(true);
        console.error(e);
      }
      this.show_add_groundstation_popup = false;
    },
    removePreferredGroundstation(gs_name) {
      try {
        if (!this.preferred_groundstations.some((gs) => gs.name == gs_name)) {
          this.error_text = "There does not exist any preferred groundstation that you have selected that has that name!";
          this.setShowErrorBox(true);
          console.error("There does not exist any preferred groundstation that you have selected that has that name!");
        } else {
          this.preferred_groundstations = this.preferred_groundstations.filter((gs) => !(gs.name == gs_name));
        }
      } catch(e) {
        this.error_text = "Failed to remove preferred groundstation because: " + this.toStringObject(e);
        this.setShowErrorBox(true);
        console.error(e);
      }
    },
    updateAddGroundstationPopup(visible) {
      this.show_add_groundstation_popup = visible;
    },
    addGroundstationToAvoid(gs_name) {
      try {
        if (this.groundstations_to_avoid.some((gs) => gs.name == gs_name)) {
          let gs = this.groundstations_to_avoid.find((gs) => gs.name == gs_name);
          this.error_text = "This groundstation to avoid is already selected! Groundstation: " + this.toStringObject(gs);
          this.setShowErrorBox(true);
          console.error("This groundstation to avoid is already selected! Groundstation: " + this.toStringObject(gs));
        } else {
          let gs = new Groundstation(this.api_groundstations[gs_name]);
          this.groundstations_to_avoid.push(gs);
        }
      } catch(e) {
        this.error_text = "Failed to add groundstation to avoid because: " + this.toStringObject(e);
        this.setShowErrorBox(true);
        console.error(e);
      }
      this.show_add_groundstation_avoid_popup = false;
    },
    removeGroundstationToAvoid(gs_name) {
      try {
        if (!this.groundstations_to_avoid.some((gs) => gs.name == gs_name)) {
          let gs = this.groundstations_to_avoid.find((gs) => gs.name == gs_name);
          this.error_text = "There does not exist any groundstation to avoid that you have selected that has that name! Groundstation: " + this.toStringObject(gs);
          this.setShowErrorBox(true);
          console.error("There does not exist any groundstation to avoid that you have selected that has that name! Groundstation: " + this.toStringObject(gs));
        } else {
          this.groundstations_to_avoid = this.groundstations_to_avoid.filter((gs) => !(gs.name == gs_name));
        }
      } catch(e) {
        this.error_text = "Failed to remove groundstation to avoid because: " + this.toStringObject(e);
        this.setShowErrorBox(true);
        console.error(e)
      }
    },
    updateAddGroundstationToAvoidPopup(visible) {
      this.show_add_groundstation_avoid_popup = visible;
    },
    makeDataForRating() {
      // if (this.selected_satellites.length === 0) throw new Error("You have selected no satellites!");
      // if (this.minimum_pass_duration_minutes === 0.0 || this.minimum_pass_duration_minutes === 0 || this.minimum_pass_duration_minutes === undefined) throw new Error("You have not set a valid mininum pass duration!");
      // if (Object.keys(this.frequency_bands_states).length === 0) throw new Error("You have to select at least one frequency band");
      this.selected_satellites.forEach((sat,) => {
        sat.supported_frequency_bands = [];
      });
      Object.keys(this.frequency_bands_states).forEach((key) => {
        if (this.frequency_bands_states[key]) {
          this.selected_satellites.forEach((sat,) => {
            if (sat.supported_frequency_bands === undefined) {
              sat.supported_frequency_bands = [];
            }
            sat.supported_frequency_bands.push(key+"-band");
          });
        }
      });
      this.selected_satellites.forEach((sat,) => {
        if (this.mission_purpose === undefined || this.mission_purpose === "" || this.mission_purpose === "Choose...") this.mission_purpose = undefined;
        else sat.mission_purpose = this.mission_purpose;
      });
      if (this.contact_time_per_day === "" || this.contact_time_per_day === null) this.contact_time_per_day = undefined;
      if (this.elevation_angle == undefined || this.elevation_angle == null) this.elevation_angle = -1; // handle in backend
      if (this.num_days == undefined || this.num_days == null) this.num_days = -1; // handle in backend
      let simulation_params = new SimulationParams(this.elevation_angle, this.num_days);
      return new DataForRating(this.selected_satellites, this.want_num_passes_predictor, this.contact_time_per_day, this.minimum_pass_duration_minutes, this.preferred_groundstations, this.groundstations_to_avoid, this.use_aws_groundstations, simulation_params);
    },
    async lookForSites() {
      this.setShowErrorBox(false);
      this.report_data = new ReportData({passes_predictor: undefined, wanted_passes: undefined});
      this.is_looking_for_site = true;
      this.start_simulation_button_text = "Processing...";
      try {
        let data = this.makeDataForRating();
        let options = await getAPIHeaderOptions("POST");
        options.headers.append("Accept", 'application/json');
        options.headers.append('Content-Type', 'application/json');
        options.body = JSON.stringify(data);

        let response = await fetch(constants.SERVER_IP+"/find_sites", options);
        let returned_data = await response.json();
        if (!response.ok) {
          console.error(returned_data);
          throw new Error(returned_data.ok_text);
        }
        this.report_data = new ReportData(returned_data);
      } catch(e) {
        console.error(e);
        this.error_text = "Failed to look for sites because: " + e.message;
        this.setShowErrorBox(true);
      } finally {
        this.is_looking_for_site = false;
        this.start_simulation_button_text = START_ANALYSIS_TEXT;
      }
    },
    async downloadPDF() {
      await this.downloadFile(this.report_data.pdf_name);
    },
    async downloadCSV() {
      await this.downloadFile(this.report_data.csv_name);
    },
    async downloadFile(file_name) {
      let options = await getAPIHeaderOptions("GET");
      let response = await fetch(constants.SERVER_IP+"/reports/"+file_name, options);
      if (!response.ok) {
        console.error("Failed to get url for PDF!");
        return;
      }
      const data = await response.json();
      const url = data.url;
      window.open(url, "_blank");
    },
    async getMyFiles() {
      let options = await getAPIHeaderOptions("GET");
      let response = await fetch(constants.SERVER_IP+"/get_my_files", options);
      if (!response.ok) {
        console.error("Failed to get my files!");
        return [];
      }
      const data = await response.json();
      this.file_urls = data.data;
    },
    async openMyFile(url) {
      window.open(url, "_blank");
    },
    setShowErrorBox(val) {
      this.show_error = val;
    },
    toStringObject(obj) {
      return Object.entries(obj).map((k, v) => `${k}: ${v}`).join(", ");
    }
  }
}
</script>