import { msalConfig, loginScopes, autositeApiScope } from '@/auth/auth-config';

import * as msal from "@azure/msal-browser";

class AuthHandler {
	static #instance = null;

	constructor() {
		this.pca = null;
	}

	async initialize() {
		if (!AuthHandler.#instance) {
			AuthHandler.#instance = await msal.createStandardPublicClientApplication(msalConfig);
		}
		this.pca = AuthHandler.#instance;
	}

	async login() {
		await this.initialize();
		try {
			await this.pca.handleRedirectPromise();

			let accounts = await this.pca.getAllAccounts();
			if (accounts.length === 0) {
				const loginResponse = await this.pca.loginRedirect({
					scopes: [loginScopes[0]]
				});
				console.log(loginResponse);
				// this.pca.setActiveAccount();
			}

			accounts = await this.pca.getAllAccounts();
			if (accounts.length === 0) {
				throw new Error("No user signed in!");
			} else if (accounts.length > 1) {
				console.log("Multiple accounts detected. Choosing the first one: " + accounts[0].name);
			}
			await this.pca.setActiveAccount(accounts[0]);
		} catch (err) {
			console.error(err);
		}
	}

	async isLoggedIn() {
		await this.initialize();
		return !(await this.pca.getActiveAccount() === null);
	}

	async getAPIToken() {
		await this.initialize();
		let result = null;
		if (await this.pca.getActiveAccount() === null) {
			console.error("There is no account active. Tring to log in again!");
			await this.login();
			// return result;
		}
		if (await this.pca.getActiveAccount() === null) {
			console.error("Failed to log in again, since no active account was given!");
			return result;
		}
		try {
			result = await this.pca.acquireTokenSilent({scopes: [autositeApiScope]});
		} catch (err) {
			console.error(err);
		}
		return result;
	}
}

export default new AuthHandler();