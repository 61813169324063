class TLESatellite {
    constructor(name, tle) {
        this.name = name;
        this.tle = tle;
    }
}

class CustomOrbit {
    constructor(A, E, I, RAAN, W, TA) {
        this.a = A;
        this.e = E;
        this.i = I;
        this.raan = RAAN;
        this.w = W;
        this.ta = TA;
    }
}

class CustomSatellite {
    constructor(name, custom_orbit) {
        this.name = name;
        if (!(custom_orbit instanceof CustomOrbit)) throw new Error("You are trying to make a custom satellite without using the CustomOrbit class for the custom orbit, which is not possible.");
        this.custom_orbit = custom_orbit;
    }
}

class Satellite {
    constructor(satellite) {
        this.name = satellite.name;
        this.tle = "";
        this.norad_id = "";
        this.custom_orbit = undefined;
        this.supported_frequency_bands = undefined;
        this.mission_purpose = undefined;
        if (satellite instanceof TLESatellite) {
            this.tle = satellite.tle;
        } else if (satellite instanceof CustomSatellite) {
            this.custom_orbit = satellite.custom_orbit;
        } else {
            throw new Error("Unknown satellite type:\n"+satellite);
        }
    }
}

class Groundstation {
    constructor(gs_info) {
        this.station_id = gs_info.station_id;
        this.name = gs_info.name;
        this.latitude = gs_info.latitude;
        this.longitude = gs_info.longitude;
        this.altitude = gs_info.altitude;
        this.supported_frequency_bands = gs_info.supported_frequency_bands;
        this.allowed_data_purposes = gs_info.allowed_data_purposes;
        this.rating = gs_info.rating;
        this.amount_of_antennas = gs_info.amount_of_antennas;
        this.score = gs_info.score;
    }
}

class SimulationParams {
    constructor(elevation_angle, num_days) {
        this.elevation_angle = elevation_angle;
        this.num_days = num_days;
    }
}

class DataForRating {
    constructor(satellites, number_of_passes_predictor, wanted_pass_duration_per_day_in_min, min_pass_duration_in_minutes, preferred_groundstations, groundstations_to_avoid, use_aws_groundstations, simulation_params) {
        this.satellites = satellites;
        this.number_of_passes_predictor = number_of_passes_predictor;
        this.wanted_pass_duration_per_day_in_min = wanted_pass_duration_per_day_in_min;
        this.min_pass_duration_in_minutes = min_pass_duration_in_minutes;
        this.preferred_groundstations = preferred_groundstations;
        this.groundstations_to_avoid = groundstations_to_avoid;
        this.use_aws_groundstations = use_aws_groundstations;
        this.simulation_params = simulation_params;
    }
}

class ReportData {
    constructor(report) {
        
        if (report.passes_predictor !== undefined && report.passes_predictor !== null && report.passes_predictor.length == 2) {
            this.passes_predictor_simple = report.passes_predictor[0];
            this.passes_predictor_complex = report.passes_predictor[1];
        }

        if (report.wanted_passes !== undefined && report.wanted_passes !== null) {
            this.wanted_passes = report.wanted_passes;
        }

        if (report.pdf_name !== undefined) this.pdf_name = report.pdf_name;
        if (report.csv_name !== undefined) this.csv_name = report.csv_name;
        
        if (this.passes_predictor_simple === null || this.passes_predictor_simple === undefined) this.passes_predictor_simple = {};
        if (this.passes_predictor_complex === null || this.passes_predictor_complex === undefined) this.passes_predictor_complex = {};
        if (this.wanted_passes === null || this.wanted_passes === undefined) this.wanted_passes = {};
    } 
}

export {
    TLESatellite,
    CustomOrbit,
    CustomSatellite,
    Satellite,
    Groundstation,
    SimulationParams,
    DataForRating,
    ReportData,
}