<template>
    <div v-if="visible" :class="constants.CSS_CLASS_POPUP" style="z-index: 10000;">
      <div :class="constants.CSS_CLASS_POPUP_CONTENT">
          <div :class="constants.CSS_CLASS_FLEX_CONTAINER">
              <h1 style="margin: 0;">{{ headerText }}</h1>
              <button :class="constants.CSS_CLASS_IGNORE_BUTTON_STYLE" style="min-width: 25px; margin-left: auto;" @click="hidePopup">
                  {{ constants.TEXT_BUTTON_CLOSE_ICON }}
              </button>
          </div>
          <slot></slot>
      </div>
    </div>
</template>

<script>
import * as constants from '@/assets/constants';


export default {
  name: 'PopupBase',
  props: {
    headerText: {
        type: String,
        required: true,
    },
    visible: {
        type: Boolean,
        required: true,
    },
  },
  data() {
    return {
        constants,
    }
  },
  methods: {
    hidePopup() {
      this.$emit('update:clicked', false);
    }
  },
};
</script>