import * as constants from "../assets/constants.js";
import msalInstance from "@/auth/msal.js"

async function getFromServer(path) {
    let options = await getAPIHeaderOptions("GET");

    const response = await fetch(constants.SERVER_IP+path, options);
    if (!response.ok) {
        throw new Error(`Response from the ${path} fetch-request was not ok!`);
    }

    return await response.json();
}

export async function getSatellites() {
    return await getFromServer("/satellites");
}

export async function getGroundstations() {
    return await getFromServer("/groundstations");
}

export async function getAPIHeaderOptions(method) {
    let token = await msalInstance.getAPIToken();
    let headers = new Headers();
    headers.append("Authorization", "Bearer " + token.accessToken);
    return {
        method: method,
        headers: headers
    };
}