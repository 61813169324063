<template>
    <div class="flex-container">
            <input :id="frequency_band+SUFFIX_ID_SELECTED_BAND" type="checkbox" :checked="checked" @change="checkedBoxChanged">
            <span>{{ frequency_band }}</span>

            <!-- TODO: Implement the addition of specific frequency
            <div>frequency (in MHz):</div>
            <input id={{ frequency_band }}+"frequency"> -->
    </div>
</template>

<script>
import { SUFFIX_ID_SELECTED_BAND } from '@/assets/constants';

export default {
  name: 'FrequencyBandSelection',
  props: {
    frequency_band: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      SUFFIX_ID_SELECTED_BAND
    };
  },
  methods: {
    checkedBoxChanged(event) {
      this.$emit('update:checked', event.target.checked, this.frequency_band);
    }
  },
};
</script>