<template>
    <!-- <div :class="constants.CSS_CLASS_FLEX_CONTAINER + ' ' + constants.CSS_CLASS_REMOVABLE_ELEMENT">
        <span>{{object_name}}</span>
        <slot></slot>
        <button :class="constants.CSS_CLASS_IGNORE_BUTTON_STYLE" style="min-width: 25px;" @click="removeBlock">{{constants.TEXT_BUTTON_CLOSE_ICON}}</button>
    </div> -->
    <tr :class="constants.CSS_CLASS_REMOVABLE_ELEMENT">
      <td>{{ object_name }}</td>
      <slot></slot>
      <td>
        <button :class="constants.CSS_CLASS_IGNORE_BUTTON_STYLE" style="min-width: 25px;" @click="removeBlock">{{constants.TEXT_BUTTON_CLOSE_ICON}}</button>
      </td>
    </tr>
</template>

<script>
import * as constants from "../assets/constants";

export default {
  name: 'RemovableBlock',
  props: {
    object_name: {
        type: String,
        required: true,
    }
  },
  data() {
    return {
        constants,
    }
  },
  methods: {
    removeBlock() {
      this.$emit("update:clicked", this.object_name);
    }
  }
};
</script>