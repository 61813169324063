// TODO: All these should probably be moved to env variables we can retrieve from the server or something along those lines
const tenant = {
	tenantName: "ksatno",
	tenantId: "c08666d8-aaad-427a-b126-289667868626",
	appAutositeProductionClientID: "3c2085a9-e571-4f9c-bd8b-bdee63a4575b",
	apiAutositeProductionClientID: "b09aafaa-a010-43bf-87eb-aa386b6fa599",
};

// TODO: Verify B2C is gone for good, then remove entirely
// const b2cPolicies = {
// 	names: {
// 		signIn: "b2c_1_signin"
// 	},
// 	authorities: {
// 		signIn: {
// 			authority: `https://${tenant.tenantName}.b2clogin.com/${tenant.tenantName}.onmicrosoft.com/b2c_1_signin`
// 		}
// 	},
// 	authorityDomain: `${tenant.tenantName}.b2clogin.com`
// };

const msalConfig = {
	auth: {
		clientId: tenant.appAutositeProductionClientID,
		authority: "https://login.microsoftonline.com/" + tenant.tenantId,
		// knownAuthorities: [b2cPolicies.authorityDomain],
		redirectUri: window.origin
	},
	cache: {
		cacheLocation: "sessionStorage"
	},
	system: {
		iframeHashTimeout: 10000 // To attempt circumvention of login issues, up from default 6s to 10s
	}
};

/**
 * Scopes used to acquire a token during login phase.
 */
const loginScopes = ["openid", "profile"];

const autositeApiScope = `https://${tenant.tenantName}.onmicrosoft.com/${tenant.apiAutositeProductionClientID}/access`;

export { msalConfig, loginScopes, autositeApiScope };